import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import './Grid.scss'
import BluredPic from '../BluredPic/BluredPic'

const Grid = ({ images }) => {
  return (
    <div className="container py-3">
      <div className="row">
      {
        images?.slice(0, images.length - 1).map((img, i) => (
          <div key={i} className="col-lg-6 col-md-4 mb-4 justify-content-center grid-item">
            <GatsbyImage
              image ={getImage(img)}
              alt=""
              className="grid-item-img"
            />
          </div>
        ))
      } 
      <BluredPic img={images[images.length - 1]} clase1="col-lg-6 col-md-4 mb-4 justify-content-center grid-item" clase2="img-see-more grid-item-img"/>
      </div>
    </div>
  )
}

export default Grid
