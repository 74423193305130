import React, { useState } from "react";
import { Link } from "gatsby";
import Carousel from "../../components/Carousel/Carousel";
import Grid from "../../components/Grid/Grid";
import gridButton from "../../images/buttons/squares-grid.png";
import carouselButton from "../../images/buttons/square-lista.png";
import "./ProjectItem.scss";

const ProjectItem = ({ title, subtitle, year, slug, imgs }) => {
  const [state, setState] = useState(true);

  const handleClick = () => {
    setState(!state);
  };

  return (
    <>
      {state ? (
        <div className="container mb-5 pb-5">
          <div className="row justify-content-center">
            <div className="col-md-11 col-lg-11 col-sm-12 carousel-container">
              <Carousel
                images={imgs}
                name={`${title}, ${year}`}
                linked={true}
                slug={`proyectos/${slug}`}
              />
            </div>
            <div className="justify-content-center name-projectItem text-center mb-2">
              <p>
                {" "}
                <b>{title}</b> <br />
                <small>
                  {subtitle}, {year}
                </small>
              </p>
            </div>

            <div className="col-1 xs-responsive-menu">
              <img
                onClick={handleClick}
                src={gridButton}
                alt="click"
                style={{ width: 25 }}
                className="buttonProject"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="container mb-5 pb-5 xs-responsive-menu">
          <div className="row pb-5">
            <div className="col-11">
              <p className="ProjectItem-text-grid">
                {title}, {year}
              </p>
              <Link to={`/proyectos/${slug}`}>
                <Grid images={imgs} />
              </Link>
            </div>

            <div className="col-1">
              <img
                onClick={handleClick}
                src={carouselButton}
                alt="click"
                style={{ width: 25 }}
                className="buttonProject"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectItem;
