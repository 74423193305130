import useDevice from "./useDevice";

const BREAKPOINTS = {
    sm: 880,
}

const useIsMobile = () => {
  const windowSize = useDevice();

  if (!windowSize) {
    return false;
  }
  if (windowSize <= BREAKPOINTS.sm) {
    return true;
  }

  return false;
};

export default useIsMobile;
