import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../fragments/layout/Layout";
import ProjectItem from "../fragments/ProjectItem/ProjectItem";
import { TransitionState } from "gatsby-plugin-transition-link";
import useSeoQuery from "../queries/useSeoQuery"
import Seo from "../components/seo"
import useIsMobile from "../hooks/useIsMobile";

const useProjects = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProyecto(sort: { fields: order, order: ASC }) {
        edges {
          node {
            ao
            lugarTtulo
            subttulo
            primerasImgsMobile {
              gatsbyImageData(
                width: 400,
                placeholder: DOMINANT_COLOR, 
                layout: CONSTRAINED
              )
            }
            primerasImgsDesktop {
              gatsbyImageData(
                width: 1200,
                placeholder: DOMINANT_COLOR, 
                layout: CONSTRAINED
              )
            } 
            slugUrl
          }
        }
      }
    }
  `);

  return data;
};

const Proyectos = React.memo(() => {
  const data = useProjects();
  const seo = useSeoQuery()
  const isMobile = useIsMobile();
  // const [renderFull, setRenderFull] = useState(false);
  const proyects = data.allContentfulProyecto.edges;

  const renderProjects = (proyects, isMobile) => {
    return (
      <div className={isMobile ? "mobileListPortada" : "desktopListPortada"}>
        {proyects.map((proyect, i) => (
          <ProjectItem
            key={i}
            name="cambiar"
            title={proyect.node.lugarTtulo}
            subtitle={proyect.node.subttulo || "Proyecto"}
            year={proyect.node.ao}
            slug={proyect.node.slugUrl}
            imgs={ isMobile ? 
                    [...proyect.node.primerasImgsMobile].filter((image) => image)
                  : [...proyect.node.primerasImgsDesktop].filter((image) => image).slice(0, 4)
            }
          />
        ))}
      </div>
    ) 
  }

  // const minProyects = proyects.slice(0, 3);

  // const setFull = () => {
  //   setTimeout(() => {
  //     setRenderFull(true);
  //   }, 900);
  // };

  return (
    <Layout>
    <Seo
        title="Proyectos"
        lang="es"
        titleSEO={seo.proyectosTitleSeo}
        description={seo.proyectosDescriptionSeo.proyectosDescriptionSeo}
      />
      <TransitionState>
        {({ transitionStatus, mount }) => {
          // if (transitionStatus === "entered") {
          //   setFull();
          // }
          // const renderProyects = renderFull ? proyects : minProyects;
          if(mount){
            return  (
              <>
                <div className="marginTopFather"></div>
              
                {renderProjects(proyects, isMobile)}
              </>
            )};
          }
        }
      </TransitionState>
    </Layout>
  );
});

export default Proyectos;
