import { useEffect, useState } from 'react';

const useDevice = () => {
  const [windowSize, setWindowSize] = useState(undefined);

  useEffect(() => {
    setWindowSize(window.innerWidth);
  }, []);
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize(window.innerWidth);
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};

export default useDevice;
