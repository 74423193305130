import React from 'react'
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./BluredPic.scss"

const BluredPic = ({img, clase1, clase2 }) => {
  return (
    <div className={clase1}>
      <span className="button-see-more">Ver más</span>
      <GatsbyImage
        image={getImage(img)}
        alt=""
        className={clase2}
      />
    </div>
  )
}

export default BluredPic
